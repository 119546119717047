
.products_features{
	text-align: center;
	margin-top: 110px;

	.wrapper{

		.btn_more{
			display: inline-block;
			margin: 30px 0 30px 0;
		}

		h3{
			font-size: 20px;
			font-family: 'Spartan';
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 40px;
		}

		.sizes{
			display: block;
			max-width: 550px;
			margin: 0 auto 55px auto;

			.note{
				margin-top: 30px;
			}

			form{

				.item{
					text-align: center;

					p{
						font-size: 15px;
						font-family: 'Rubik';
						text-transform: uppercase;
						font-weight: bold;
					}

					select{
						display: block;
						width: 100%;
						border: 1px solid #a4dda4;
						padding: 8px 0px 8px 25px;
						border-radius: 5px;
						outline: none;

						option{
							text-transform: uppercase;
						}
					}
				}
			}

		}
	}
}


.products_gallery{
	text-align: center;
	margin: 20px 0;
	padding: 50px 0;

	.wrapper{

		.products_list{
			margin-top: 20px;
			display: none;

			h4{
				font-family: 'Spartan';
				font-size: 20px;
				color: #002e60;
				font-weight: bold;
				text-transform: uppercase;
				margin-top: 50px;
			}



		}

		.title{
			text-transform: uppercase;
			font-family: 'Spartan';
			font-size: 25px;
			color: #efb35b;
			font-weight: bold;

			span{
				color: #002e60;
			}
		}

		p{
			display: block; 
			font-family: 'Fauna One';
			font-size: 20px;
			color: #002e60;
			margin: 0 auto;
			max-width: 600px;

		}

		.gallery{
			margin-top: 40px;

			.row{

				.img{
					display: block;
					width: 100%;
					height: auto;

					img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}



@media screen and (max-width: 768px){


	.products_features{
		/*text-align: center;
		margin-top: 30px;*/

		.wrapper{

			.btn_more{
				/*display: inline-block;
				margin: 30px 0 30px 0;*/
			}

			h3{
				/*font-size: 20px;
				font-family: 'Spartan';
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 40px;*/
			}

			.sizes{
				/*display: block;
				max-width: 550px;
				margin: 0 auto 55px auto;*/

				form{

					.item{
						text-align: center;

						p{
							/*font-size: 15px;
							font-family: 'Rubik';
							text-transform: uppercase;
							font-weight: bold;*/
						}

						select{
							/*display: block;
							width: 100%;
							border: 1px solid #a4dda4;
							padding: 8px 0px 8px 25px;
							border-radius: 5px;
							outline: none;*/
							padding: 8px 10px;
							margin-bottom: 20px;

							option{
								/*text-transform: uppercase;*/
							}
						}
					}
				}

			}
		}
	}


	.products_gallery{
		/*text-align: center;
		margin: 20px 0;
		padding: 50px 0;*/

		.wrapper{

			.title{
				/*text-transform: uppercase;
				font-family: 'Spartan';
				font-size: 25px;
				color: #efb35b;
				font-weight: bold;*/
				font-size: 22px;

				span{
					/*color: #002e60;*/
				}
			}

			p{
				/*display: inline-block; 
				font-family: 'Fauna';
				font-size: 20px;
				color: #002e60;
				margin: 0 auto;
				max-width: 600px;*/
				font-size: 18px;

			}

			.gallery{
				/*margin-top: 40px;*/
				margin-top: 60px;

				.row{

					.img{
						/*display: block;
						width: 100%;
						height: 185px;
						background: #ccc;*/
						width: 80%;
						margin: 0 auto;
						height: auto;
						margin-bottom: 40px;
					}
				}
			}
		}
	}

}