body{
	color: #4d4d4d;
	font-family: 'Fauna One';

}

.wrapper{
	display: block;
	max-width: 1080px;
	margin: 0 auto;
	padding: 0 20px;
}

a{
	transition: all 0.2s;

	&:hover{
		text-decoration: none;
		color: #a4dda4;
	}
}

.arrow-down{
	display: block;
	margin-top: 20px;

	span{
		color: #efb35b;
		font-size: 16px;
	}

}


.btn_more{
	color: #000;
	text-transform: uppercase;
	font-family: 'Spartan';
	font-weight: bold;
	font-size: 20px; 
	display: inline-block;
	margin-top: 30px;
	border-bottom: 1px solid #a4dda4;

}

.masthead{
	background: blue;
	padding: 20px 0;
	text-align: center;
	background: url('../images/producto-1.jpeg') center center no-repeat;
	background-size: cover;
	position: relative;
	background-attachment: fixed;

	.brdr{
		background: #FFF;
		display: block;
		width: 100%;
		height: 140px;
		position: absolute;
		bottom: 0;
		z-index: 9;
		border-top: 4px solid #a4dda4;

	}

	&.nosotros{
		padding-bottom: 60px;
		border-bottom: 4px solid #efb35b;
		background: url('../images/banner2.jpeg') bottom center no-repeat;
		background-size: cover;
		background-attachment: fixed;

		&::after{
			content: '';
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgb(0,21,63);
			background: -moz-linear-gradient(0deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
			background: -webkit-linear-gradient(0deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
			background: linear-gradient(0deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00153f",endColorstr="#00153f",GradientType=1);
		}

		.wrapper{

			.content{
				margin-top: 260px;

				p{
					margin: 0;
				}
			}
		}
	}

	&.product{
		height: 800px;
		position: relative;
		background: url('../images/header-bg.jpeg') center center no-repeat;
		background-size: cover;
		background-attachment: fixed;

		&::after{
			content: '';
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgb(36,85,86);
			background: -moz-linear-gradient(180deg, rgba(36,85,86,1) 0%, rgba(36,85,86,0.6012780112044818) 100%);
			background: -webkit-linear-gradient(180deg, rgba(36,85,86,1) 0%, rgba(36,85,86,0.6012780112044818) 100%);
			background: linear-gradient(180deg, rgba(36,85,86,1) 0%, rgba(36,85,86,0.6012780112044818) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#245556",endColorstr="#245556",GradientType=1);
		}

		.wrapper{

			.img_box{
				max-width: 400px;
				height: auto;
				margin: 0 auto;
				z-index: 9999;
				clear: both;
				position: absolute;
				left: 0;
				right: 0;

				img{
					width: 100%;
					height: auto;

				}
			}
		}
	}

	&::after{
		content: '';
		display: block;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(0,21,63);
		background: -moz-linear-gradient(180deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
		background: -webkit-linear-gradient(180deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
		background: linear-gradient(180deg, rgba(0,21,63,1) 0%, rgba(0,21,63,0.5984768907563025) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00153f",endColorstr="#00153f",GradientType=1);
	}

	.wrapper{
		position: relative;
		z-index: 9;


		.main_nav{
			display: block;

			.logo{
				display: block;
				width: 200px;
				height: auto;
				margin: 0;


				img{

					width: 100%;
					height: auto;
				}
			}

			.btn_menu{
				display: none;
			}

			.menu{
				list-style: none;
				padding: 0;
				margin: 0;
				position: absolute;
				top: 30px;
				right: 10px;

				li{
					display: inline-block;
					margin: 0 8px;

					a{
						text-transform: uppercase;
						color: #FFF;
						font-size: 13px;
						font-family: 'Spartan';
						font-weight: bold;
					}
				}

			}
		}


		.content{
			margin-top: 80px;

			.title{
				font-size: 45px;
				font-family: 'Spartan';
				text-transform: uppercase;
				color: #FFF;
				font-weight: 700;

				span{
					color: #a4dda4;
				}

			}

			p{
				font-size: 25px;
				color: #FFF;

				span{
					color: #a4dda4;

				}
			}
			
		}

		.img_box{
			max-width: 560px;
			height: auto;
			margin: 0 auto;
			z-index: 9999;
			clear: both;

			img{
				width: 100%;
				height: auto;

			}
		}

		.btn_more{
			color: #FFF;

			&:hover{
				color: #eeb262;
			}

		}
	}
}


.features{
	text-align: center;
	padding-bottom: 80px;

	.wrapper{

		.txt{
			margin-bottom: 60px;

			h3{
				font-family: 'Spartan';
				font-size: 20px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0 0 15px 0;
				color: #000;

				span{
					color: #6DA38D;
				}
			}

			p{
				font-size: 15px;
			}
		}

		.row{

			.feature{
				margin-bottom: 60px;

				.icon{

					transition: all .4s ease-in-out;

					img{
						width: 100%;
						height: auto;
					}

					&:hover{

						transform: scale(1.07);

					}
				}

				h5{
					font-family: 'Spartan';
					font-size: 20px;
					font-weight: bold;
					text-transform: uppercase;
					color: #a4dda4;
					margin-top: 25px;

					&.blue{
						color: #002e60; 

					}

				}

				p{
					font-size: 15px;

				}
			}
		}

		.btn_more{

		}
	}
}



.about{
	background: #fff;
	padding: 100px 0;
	text-align: center;
	position: relative;

	.float_prods{
		display: block;
		width: 320px;
		height: 525px;
		position: absolute;
		top: 0;
		
		
		z-index: 999;

		&.right{
			background: url('../images/prod2.png') center center no-repeat;
			right: 0;
			background-size: 100%;
			top: -215px;
		}

		&.left{
			background: url('../images/prod22.png') center center no-repeat;
			left: 0;
			background-size: 100%;
			top: 280px;
		}
	}

	&::after{
		content: '';
		display: block;
		width: 100%;
		height: 525px;
		background: url('../images/bg-gray.png') center center no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	&.products{
		background: #FFF;
		position: relative;

		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 525px;
			background: url('../images/bg-blue.png') center center no-repeat;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		.btn_more{
			color: #FFF;
		}

		.txt{

			h3{
				color: #FFF !important;

				span{
					color: #6da38d !important;
				}
			}

			p{
				color: #FFF !important;
			}
		}

	}

	.wrapper{
		z-index: 9;
		position: relative;

		.txt{
			display: block;
			max-width: 720px;
			height: auto;
			margin: 0 auto;

			h3{
				font-family: 'Spartan';
				font-size: 30px;
				font-weight: bold;
				text-transform: uppercase;
				margin: 0 0 15px 0;
				color: #002e60;
				padding: 0 80px;

				span{
					color: #efb35b;
				}
			}

			p{
				font-size: 20px;
				color: #002e60;

			}
		}

		.arrow{

		}

		.video_box{
			display: block;
			margin: 40px auto;

		}

		.bot_txt{
			padding: 0 50px;
		}

		.bio{
			margin: 80px 0;

			.row{

				.col_alt{
					padding-left: 0;
					padding-right: 0;
				}

				.img{
					width: 100%;
					height: 100%;
					margin: 0;

					img{
						width: 100%;
						height: 100%;
					}
				}

				.txt{
					display: block;
					width: 100%;
					background: rgb(0,0,99);
					background: -moz-linear-gradient(90deg, rgba(0,0,99,1) 0%, rgba(25,74,139,1) 100%);
					background: -webkit-linear-gradient(90deg, rgba(0,0,99,1) 0%, rgba(25,74,139,1) 100%);
					background: linear-gradient(90deg, rgba(0,0,99,1) 0%, rgba(25,74,139,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000063",endColorstr="#194a8b",GradientType=1);
					text-align: left;
					padding: 88px 40px;

					h4{
						color: #efb35b;
						text-transform: uppercase;
						font-family: 'Spartan';
						font-size: 20px;
						font-weight: bold;

					}

					p{
						color: #FFF;
						font-size: 15px;
						margin: 0;

					}

					.btn_more{
						font-size: 15px;
						color: #FFF;
						border-bottom: 1px solid #FFF;
						transition: 0.3s all;

						&:hover{
							color: #eeb262;
							border-color: #eeb262;

						}

					}
				}
			}
		}

		p{
			font-size: 20px;

		}

		.brands{
			display: block;
			width: 100%;
			height: auto;
			background: #555;
			margin: 60px 0;

			figure{

				img{
					width: 100%;
					height: auto;
				}
			}

		}

		.quotes{

			.item{

				h4{
					font-family: 'Fauna';
					font-size: 36px;
					color: #002e60;
					margin: 0 0 15px 0;

				}

				p{
					font-family: 'Rubik';
					font-size: 18px;
					color: #002e60;
					margin: 0;

					&.job{
						text-transform: uppercase;
					}

				}
			}


		}
	}
}

.map{
	background: #efb35b;
	padding: 50px 0 0 0;
	position: relative;


	&::after{
		content: '';
		display: block;
		width: 600px;
		height: 300px;
		background: url('../images/prods.png') center center no-repeat;
		background-size: 100%;
		position: absolute;
		top: 90px;
		right: 0;
	}

	&.blue{
		background: #aab9ca;

		.brdr{

			background: rgb(0,21,63);
			background: -moz-linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
			background: linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00153f",endColorstr="#557495",GradientType=1);
		}
	}

	&.green{
		background: #a4dda4;
	}

	.brdr{
		background: #002342;
		display: block;
		width: 100%;
		height: 230px;
		position: absolute;
		bottom: 0;
		z-index: 9;

	}

	.wrapper{
		z-index: 9999;
		clear: both;
		position: relative;

		h4{
			font-family: 'Spartan';
			font-size: 36px;
			color: #002e60;
			font-weight: bold;
			text-transform: uppercase;
			margin: 10px 0 20px 0;
		}

		h5{
			font-family: 'Spartan';
			font-size: 24px;
			color: #FFF;
			font-weight: bold;
			text-transform: uppercase;
		}

		p{
			font-family: 'Rubik';
			font-size: 18px;
			color: #002e60;
			line-height: 24px;
			margin: 0;
			display: block;
			max-width: 900px;
		}

		.map_img{
			margin: 20px 0 0 0;
			display: block;
			max-width: 600px;
			height: auto;
			z-index: 999;
			position: relative;

			img{

				width: 100%;
				height: auto;
			}
		}
	}
}

.footer{
	background: #002342;
	padding: 60px 0 15px 0;

	.wrapper{

		.connect{
			margin-bottom: 60px;

			h4{
				color: #FFF;
				text-transform: uppercase;
				font-family: 'Spartan';
				font-weight: bold;
				font-size: 20px;
			}

			.social{
				list-style: none;
				margin: 0;
				padding: 0;

				li{
					display: inline-block;
					width: 30px;
					height: 30px;
					border: 1px solid #FFF;
					border-radius: 50px;
					margin: 0 5px;
					text-align: center;
					line-height: 28px;
					transition: all 0.3s;

					&:hover{
						background: #eeb262;
						border-color: #eeb262;
					}

					span{
						color: #FFF;
						font-size: 15px;
					}
					
				}
			}
		}

		.row{

			.title{
				color: #6da38d;
				font-size: 15px;
				font-family: 'Rubik';
				margin: 0 0 10px 0;

			}

			.footer_nav{
				list-style: none;
				padding: 0;
				margin: 0;

				li{
					display: block;
					font-size: 15px;
					color: #FFF;
					font-family: 'Rubik';
					margin-bottom: 5px;

					a{
						font-size: 15px;
						color: #FFF;
						font-family: 'Rubik';
						text-decoration: underline;

						&:hover{
							color: #eeb262;
						}
					}
				}
			}

			.form_contact{

				input{
					display: block;
					width: 100%;
					margin: 8px 0;
					padding: 10px 15px;
					border-radius: 5px;
					background: #002e60;
					color: #FFF;
					border: none;
					font-family: 'Rubik';
					font-size: 15px;


					&::placeholder { 
					  color: #FFF;
					  text-transform: uppercase;
					}

					&:-ms-input-placeholder { 
					  color: #FFF;
					  text-transform: uppercase;
					}

					&::-ms-input-placeholder { 
					  color: #FFF;
					  text-transform: uppercase;
					}


				}

				textarea{
					display: block;
					width: 100%;
					margin: 8px 0;
					padding: 10px 15px;
					border-radius: 5px;
					background: #002e60;
					color: #FFF;
					border: none;
					font-family: 'Rubik';
					font-size: 15px;
					resize: none;
					height: 120px;

					&::placeholder {
					  color: #FFF;
					  text-transform: uppercase;
					}

					&:-ms-input-placeholder {
					  color: #FFF;
					  text-transform: uppercase;
					}

					&::-ms-input-placeholder { 
					  color: #FFF;
					  text-transform: uppercase;
					}

				}

				.send_btn{
					border: none;
					color: #FFF;
					outline: none;
					font-family: 'Rubik';
					text-align: left;
					background: none;
					display: block;
					width: auto;
					border-radius: 0;
					padding: 10px 0 3px 0;
					text-transform: uppercase;
					border-bottom: 1px solid #FFF;
					position: relative;
					transition: 0.3s all;

					&:hover{
						color: #eeb262;
						border-color: #eeb262;
					}

					span{
						font-size: 10px;
						color: #6da38d;
						position: absolute;
						right: -20px;
						top: 15px;
					}
				}
			}
		}

		.logo_alt{
			display: inline-block;
			width: 200px;
			height: auto;
			margin-top: 50px;

			img{
				width: 100%;
				height: auto;

			}
		}

		.cr{
			text-align: center;
			font-family: 'Rubik';
			font-size: 15px;
			color: #002e60;
			margin-top: 15px;
		}
	}
}

@media screen and (max-width: 1760px){

	.map{

		&::after{
			right: -150px;
		}
	}
}


@media screen and (max-width: 1480px){

	.about{

		.float_prods{

			&.right{
				right: -120px;
			}

			&.left{
				left: -120px;
			}
		}
	}

	.map{


		&::after{
			right: -250px;
		}
	}
}

@media screen and (max-width: 1230px){

	.about{

		.float_prods{

			&.right{

				right: -200px;
			}

			&.left{

				left: -200px;
			}
		}
	}

	.map{


		&::after{

			right: -320px;
		}
	}
}

@media screen and (max-width: 1120px){

	.map{


		&::after{
			display: none;
		}
	}
}

@media screen and (max-width: 1068px){

	.about{

		.float_prods{

			&.right{
				display: none;
			}

			&.left{
				display: none;
			}
		}
	}
}




@media screen and (max-width: 768px){

	.btn_more{
		font-size: 16px;
	}

	.masthead{

		&.product{
			height: 935px;
			position: relative;

			.wrapper{

				.img_box{
					max-width: 340px;
				}
			}
		}

		.wrapper{

			.main_nav{
				position: relative;


				.logo{
					width: 125px;
					margin: 0 auto;

				}

				.btn_menu{
					display: block;
					background: url('../images/menu.png') center center no-repeat;
					background-size: cover;
					width: 40px;
					height: 30px;
					position: absolute;
					top: 10px;
					right: 0px;
				}

				.menu{
					position: relative;
					right: 0;
					top: 20px;

					border-top: 3px solid #FFF;
					background: #020242;
					text-align: center;
					display: block;
					width: 100%;
					
					padding-right: 20px;

					height: 0;
					overflow: hidden;
					transition: 0.7s all;

					&.open{
						height: 250px;
					}

					li{
						display: block;
						margin: 24px 0;

						a{
							font-size: 14px;
							font-weight: bold;
							display: block;
							width: 100%;
							padding: 5px 0;
						}
					}

				}
			}


			.content{
				margin-top: 100px;

				.title{
					font-size: 36px;
				}

				p{
					font-size: 22px;
				}	
			}
		}
	}


	.features{

		.wrapper{

			.txt{
				margin-top: 70px;
			}

			.row{

				.feature{

					.icon{
						width: 70%;
						margin: 20px auto;
					}

					h5{
						font-size: 19px;
					}
				}
			}

			.btn_more{

			}
		}
	}



	.about{
		padding: 70px 0;

		.float_prods{
			display: none;
		}

		.wrapper{

			.txt{

				h3{
					font-size: 26px;
				}

				p{
					font-size: 18px;

				}
			}

			.bio{

				.row{

					.img{
						width: 100%;
						height: auto;
						margin-bottom: 0px;

						img{
							height: auto;
						}
					}
				}
			}

			p{
				font-size: 18px;

			}

			.quotes{

				.item{

					h4{
						font-size: 28px;

					}

					p{
						font-size: 16px;

					}
				}
			}
		}
	}

	.map{

		&::after{
			content: '';
			display: none;
		}

		&.blue{
			padding: 65px 0 0 0;
		}

		.brdr{
			height: 175px;

		}

		.wrapper{

			h4{
				font-size: 30px;
			}

			h5{
				font-size: 20px;
			}
		}
	}

	.footer{

		.wrapper{

			.connect{
				margin-bottom: 40px;

				h4{
					font-size: 18px;
				}

			}

			.row{

				.footer_nav{
					margin: 10px 0 0 0;
				}

				.form_contact{
					margin-top: 50px;
				}
			}
		}
	}
}












