
.philo{
	text-align: center;
	padding: 85px 0 60px 0;

	.wrapper{

		.title{
			font-family: 'Spartan';
			font-size: 30px;
			color: #efb35b;
			text-transform: uppercase;
			margin-bottom: 50px;
			font-weight: bold;
		}

		.row{

			h4{
				font-family: 'Spartan';
				font-size: 20px;
				color: #002e60;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 18px;
			}

			p{
				font-family: 'Rubik';
				font-size: 15px;
				padding: 0 50px;
			}

			ul{
				list-style: none;
				margin: 0;
				padding: 0;

				li{
					font-family: 'Rubik';
					font-size: 15px;
				}
			}
		}

		.txt_bottom{
			margin-top: 80px;

			h4{
				font-family: 'Spartan';
				font-size: 30px;
				color: #efb35b;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 15px;

			}

			p{
				font-family: 'Fauna One';
				color: #002e60;
				font-size: 20px;
				padding: 0 60px;

			}
		}
	}
}

.img_gallery{
	padding: 50px 0 80px 0;
	background: rgb(0,21,63);
	background: -moz-linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
	background: linear-gradient(90deg, rgba(0,21,63,1) 0%, rgba(85,116,149,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00153f",endColorstr="#557495",GradientType=1);

	.wrapper{

		.carousel{
			display: block;
			max-width: 835px;
			height: auto;
			margin: 0 auto;
		}
	}
}



@media screen and (max-width: 768px){

	.philo{

		.wrapper{

			.row{

				p{
					margin-bottom: 50px;
				}

				ul{
					margin-bottom: 50px;

				}
			}

			.txt_bottom{

				p{
					font-size: 18px;

				}
			}
		}
	}

	.img_gallery{
		padding: 80px 0;

		.wrapper{

			.carousel{

			}
		}
	}

}






