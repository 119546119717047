html, body {
  font-family: 'Arial';
  font-size: 100%;
}

body {
  background-color: #fff;
  color:#505050;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

