// External libs
@import '../../node_modules/breakpoint-sass/stylesheets/breakpoint';

// Functions
@import 'functions/rem-calc';

// Core

@import 'core/normalize';
@import 'core/accessibility';
@import 'core/breakpoints';
@import 'core/colors';
@import 'core/fonts';
@import 'core/global';
@import 'core/page-layout';
@import 'core/typography';

// Components
@import 'components/buttons';
@import 'components/lists';
@import 'components/tabs';

// Templates
@import 'templates/home';
@import 'templates/producto';
@import 'templates/nosotros';




